import { useEffect, useState } from "react";
import Download from "../../component/Icon/Download";
import TableDownloadRow from "../../component/TableDownloadRow";
import { useParams } from 'react-router-dom';
import {QRCodeSVG} from 'qrcode.react';
import Counter from '../../component/Counter'
import Lock from './../../component/Icon/Lock'
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

interface IStore {
  alias: string,
  hasPassword: false,
  title: string,
  content?: string,
  time: number,
  hasArchive?: boolean,
  start: number,
  files: {
    _id: string,
    title: string,
    size: number
  }[]
}

interface IEncryptStore {
  alias: string,
  hasPassword: true,
  title: string,
  time: number,
  start: number,
  error?: string
}

export default function ShowPage() {

  const { slug } = useParams();

  if (!slug) {
    window.location.href = `/404`;
  }

  const [store, setStore] = useState<IStore | IEncryptStore>()
  const [password, setPassword] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const load = async () => {
    setIsLoading(true)

    let URL = `${process.env.REACT_APP_BACK_HOST}/api/v1/store/${slug}`;

    if (!!password) {
      const params = new URLSearchParams({
        password
      });

      URL += `?${params}`
    }

    const response = await fetch(URL, {
      credentials: 'include',
      headers: {
        "Content-Type": "application/json"
      }
    });

    if (!response.ok && response.status !== 422) {
      window.location.href = `/404`;
    }

    const responseStore: IStore | IEncryptStore = await response.json();

    setStore({
      ...responseStore,
      time: responseStore.time || 0
    })

    setIsLoading(false)
  };

  useEffect(() => {
    load();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug])

  const downloadSingleFile = async (slug: string, fileId: string, filename: string) => {
    const url = `${process.env.REACT_APP_BACK_HOST}/api/v1/store/${slug}/file/${fileId}`;

    const link = document.createElement('a');
    link.href = url;
    link.download = filename;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }

  const copy = () => {
    navigator.clipboard.writeText(window.location.href);
  }

  const download = (slug: string) => {
    let url = `${process.env.REACT_APP_BACK_HOST}/api/v1/store/${slug}/download`;

    if (!!password) {
      const params = new URLSearchParams({
        password
      });

      url += `?${params}`
    }

    const link = document.createElement('a');
    link.href = url;
    link.download = `${slug}.zip`;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }

  return (
    <>
      <Helmet>
        <title>DO Share | Your content here</title>
      </Helmet>

      <div className="relative">
        <div className="py-6">
          {!!store && (
            <div className="max-w-3xl m-auto px-5 grid gap-4 grid-cols-1">

              {store.hasPassword && !!store.error && (
                <div 
                  className="p-4 text-red-800 rounded-lg bg-red-50" role="alert"
                >
                  <span className="font-bold">Error !</span> {store.error}
                </div>
              )}

              <div className="flex md:flex-row flex-col gap-4">

                <div className="flex flex-col gap-4 w-full">
                  <div className="relative">
                    <div
                      className="block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-slate-500 focus:border-slate-500"
                    >
                      {window.location.href}
                    </div>
                    <button
                      type="button"
                      className="text-white font-bold absolute end-2.5 bottom-2.5 bg-slate-700 hover:bg-slate-800 focus:ring-4 focus:outline-none focus:ring-slate-300 rounded-lg px-4 py-2"
                      onClick={copy}
                    >
                      Copy
                    </button>
                  </div>

                  {store.start && store.time && (
                    <div className="text-center">
                      Available: <strong><Counter start={store.start} time={store.time} /></strong>
                    </div>
                  )}

                  <div className="text-center">
                    
                  </div>
                </div>
                
                <div className="m-auto">
                  <QRCodeSVG value={window.location.href} />
                </div>
              </div>

              {store.hasPassword && (
                <div className="flex gap-4">
                  <input
                    type="text"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="block p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:ring-slate-500 focus:border-slate-500 grow"
                    placeholder="Password"
                    disabled={isLoading}
                  />

                  <button 
                    type="button" 
                    className="border-1 text-lg font-bold rounded-lg px-4 py-3 bg-slate-600 text-white flex items-center justify-center gap-2 hover:bg-slate-800 focus:ring-4 focus:outline-none focus:ring-slate-30 w-24"
                    onClick={() => load()}
                  >
                    <Lock />
                  </button>
                </div>
              )}

              {!store.hasPassword && !!store.content && (
                <div>
                  <div 
                    className="block p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-slate-500 focus:border-slate-500 whitespace-pre-wrap"
                  >
                    {store.content}
                  </div>
                </div>
              )}

              {!store.hasPassword && store.files.length > 0 && (
                <div className="relative overflow-x-auto border border-gray-300 rounded-lg">
                  <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                      <tr>
                        <th scope="col" className="px-4 py-3">
                          File
                        </th>
                        <th scope="col" className="px-4 py-3 text-end">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {store.files.map((file, index) => {
                        return (
                          <TableDownloadRow
                            key={index} 
                            title={file.title} 
                            size={file.size} 
                            download={() => downloadSingleFile(store.alias, file._id, file.title)}
                          />
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              )}

              <div className="flex gap-4">
                {!store.hasPassword && store.hasArchive && (
                  <button 
                    type="button" 
                    className="w-full border-1 text-lg font-bold rounded-lg px-4 py-3 bg-slate-600 text-white flex items-center justify-center gap-2 hover:bg-slate-800 focus:ring-4 focus:outline-none focus:ring-slate-30"
                    onClick={() => download(store.alias)}
                  >
                    Download ZIP
                    <Download />
                  </button>
                )}

                <Link 
                  to="/create" 
                  className="w-full border-1 text-lg font-bold rounded-lg px-4 py-3 bg-slate-600 text-white flex items-center justify-center gap-2 hover:bg-slate-800 focus:ring-4 focus:outline-none focus:ring-slate-30 text-center"
                >
                  Create new store
                </Link>
              </div>
              
            </div>
          )}
        </div>
      </div>
    </>
    
  );
}
